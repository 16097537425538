import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

export interface DaylightViewSlice {
    type?: 'sun' | 'virtual';
    shadow?: boolean;
    date?: Date;
    isInstant?: boolean;
}

const initialState: DaylightViewSlice = {};

export const daylightWidgetSlice = createSlice({
    name: 'daylightWidget',
    initialState,
    reducers: {
        assignDaylightState: (state, action: PayloadAction<Partial<DaylightViewSlice>>) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectDaylightView = (state: RootState): DaylightViewSlice => {
    return state.daylightWidget;
};

export const { assignDaylightState } = daylightWidgetSlice.actions;

export default daylightWidgetSlice.reducer;
