import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CsvLayerMetadata } from 'types/Layers/CsvLayerMetadata';
import { CsvLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';

type SelectedCsvLayer = {
    layerId: string;
    graphicId: number;
};
interface CsvLayerSliceState {
    originalState: CsvLayerMetadata | null;
    editingLayer: CsvLayerTreeItem | null;
    selectedCsvLayer: SelectedCsvLayer | null;
}

const initialState: CsvLayerSliceState = {
    originalState: null,
    editingLayer: null,
    selectedCsvLayer: null,
};

export const csvLayerSlice = createSlice({
    name: 'csvLayer',
    initialState,
    reducers: {
        setEditingLayer: (state, { payload }: { payload: CsvLayerTreeItem | null }) => {
            state.editingLayer = payload;
        },
        setSelectedCsvLayer: (state, { payload }: { payload: SelectedCsvLayer | null }) => {
            state.selectedCsvLayer = payload;
        },
        clearSelectedCsvLayer: (state) => {
            state.selectedCsvLayer = null;
        },
    },
});

export const { setEditingLayer, setSelectedCsvLayer, clearSelectedCsvLayer } =
    csvLayerSlice.actions;

export const selectEditingLayer = (state: RootState): CsvLayerTreeItem | null => {
    return state.csvLayer.editingLayer;
};

export const selectSelectedCsvLayer = (state: RootState): SelectedCsvLayer | null => {
    return state.csvLayer.selectedCsvLayer;
};

export default csvLayerSlice.reducer;
