import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

interface MapSizeSliceState {
    height: number;
    width: number;
}

const initialState: MapSizeSliceState = {
    height: 0,
    width: 0,
};

export const mapSizeSlice = createSlice({
    name: 'mapSize',
    initialState,
    reducers: {
        setMapSize: (state, action) => {
            state.height = action.payload.height;
            state.width = action.payload.width;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const { setMapSize } = mapSizeSlice.actions;

export const selectMapSize = (state: RootState): MapSizeSliceState => {
    return state.mapSize;
};

export default mapSizeSlice.reducer;
