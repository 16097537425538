import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

interface LayerWindowSliceState {
    isLayerWindowOpen: boolean;
    selectedTab: string;
    showLayerPanel: boolean;
}

const initialState: LayerWindowSliceState = {
    isLayerWindowOpen: false,
    selectedTab: 'layers',
    showLayerPanel: false,
};

export const layerWindowSlice = createSlice({
    name: 'layerWindowSlice',
    initialState,
    reducers: {
        setIsLayerWindowOpen: (state, action) => {
            state.isLayerWindowOpen = action.payload;
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        setShowLayerPanel: (state, action: { payload: boolean }) => {
            state.showLayerPanel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const { setIsLayerWindowOpen, setSelectedTab, setShowLayerPanel } = layerWindowSlice.actions;

export const selectIsLayerWindowOpen = (state: RootState): boolean => {
    return state.layerWindow.isLayerWindowOpen;
};

export const selectTabSelected = (state: RootState): string => {
    return state.layerWindow.selectedTab;
};

export const selectShowLayerPanel = (state: RootState) => state.layerWindow.showLayerPanel;

export default layerWindowSlice.reducer;
