import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

interface StreetViewPov {
    heading: number;
    pitch: number;
    zoom?: number;
}

export interface StreetMapViewSlice {
    visible?: boolean;
    target?: { latitude: number; longitude: number };
    pov?: StreetViewPov;
}

const initialState: StreetMapViewSlice = {};

export const streetMapViewSlice = createSlice({
    name: 'streetMapView',
    initialState,
    reducers: {
        setStreetViewVisibility: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
        setStreetViewCoordinate: (
            state,
            action: PayloadAction<{ latitude: number; longitude: number } | undefined>
        ) => {
            state.target = action.payload;
        },
        setStreetViewPov: (state, action: PayloadAction<StreetViewPov | undefined>) => {
            state.pov = action.payload;
        },
        setStreetViewState: (state, action: PayloadAction<StreetMapViewSlice | undefined>) => {
            if (!action.payload) return;
            const { pov, visible, target } = action.payload;
            state.target = target;
            state.visible = visible;
            state.pov = pov;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectStreetView = (state: RootState): StreetMapViewSlice => {
    return state.streetMapView;
};

export const {
    setStreetViewVisibility,
    setStreetViewCoordinate,
    setStreetViewPov,
    setStreetViewState,
} = streetMapViewSlice.actions;

export default streetMapViewSlice.reducer;
