//TODO: Move this to presentation slice
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AppThunk, RootState } from 'store';
import { nanoid } from 'utils/idUtils';
import { getImageProperties, ImageModelTypes } from '../helpers/imageLayerHelper';
import {
    AbsoluteOverlay,
    AbsoluteOverlayEditMetadata,
    GroundOverlay,
    GroundOverlayEditMetadata,
    ImageOverlayMetadata,
    ImageToEdit,
    ScreenOverlay,
    ScreenOverlayEditMetadata,
} from '../types/Layers/ImageLayerMetadata';
import { clearPresentation } from './presentationSlice';

interface ImageOverlaysViewState {
    screenOverlays: ScreenOverlay[];
    absoluteOverlays: AbsoluteOverlay[];
    groundOverlays: GroundOverlay[];
    imageOverlayEdit: ImageOverlayMetadata;
    imageUploaded: boolean;
    imageTab: string;
}

export const imageOverlaysInitialState: ImageOverlaysViewState = {
    screenOverlays: [],
    absoluteOverlays: [],
    groundOverlays: [],
    imageOverlayEdit: {
        itemEditing: false,
        screenOverlayEdit: {
            screenX: 0,
            screenY: 0,
            opacity: 1,
            rotation: 0,
            fullScreen: false,
            keepRatio: true,
            relativeScreenX: 0,
            relativeScreenY: 0,
            relativeWidth: 0,
            relativeHeight: 0,
        },
        absoluteOverlayEdit: {
            altitude: 0,
            tilt: 0,
            roll: 0,
            rotation: 0,
            opacity: 1,
            keepRatio: true,
        },
        groundOverlayEdit: {
            rotation: 0,
            opacity: 1,
            keepRatio: true,
        },
    },
    imageUploaded: false,
    imageTab: 'upload_tab',
};

export const imageOverlaysSlice = createSlice({
    name: 'imageOverlays',
    initialState: imageOverlaysInitialState,
    reducers: {
        setImage(state, action: PayloadAction<ImageToEdit | undefined>) {
            state.imageOverlayEdit.image = action.payload;
        },
        setClampingType(state, action: PayloadAction<ImageModelTypes | undefined>) {
            state.imageOverlayEdit.clampingType = action.payload;
        },
        setImageOverlayKey(state, action: PayloadAction<string | undefined>) {
            state.imageOverlayEdit.key = action.payload;
        },
        setImageOverlayEdit(state, action: PayloadAction<ImageOverlayMetadata>) {
            state.imageOverlayEdit = action.payload;
        },
        assignScreenOverlayEdit(state, action: PayloadAction<Partial<ScreenOverlayEditMetadata>>) {
            Object.assign(state.imageOverlayEdit.screenOverlayEdit, action.payload);
        },
        assignAbsoluteOverlayEdit(
            state,
            action: PayloadAction<Partial<AbsoluteOverlayEditMetadata>>
        ) {
            Object.assign(state.imageOverlayEdit.absoluteOverlayEdit, action.payload);
        },
        assignGroundOverlayEdit(state, action: PayloadAction<Partial<GroundOverlayEditMetadata>>) {
            Object.assign(state.imageOverlayEdit.groundOverlayEdit, action.payload);
        },
        addScreenOverlay: (state, action: PayloadAction<ScreenOverlay[]>) => {
            const screenOverlays = action.payload;
            state.screenOverlays.push(...screenOverlays);
        },
        addAbsoluteOverlay: (state, action: PayloadAction<AbsoluteOverlay[]>) => {
            const absoluteOverlays = action.payload;
            state.absoluteOverlays.push(...absoluteOverlays);
        },
        addGroundOverlay: (state, action: PayloadAction<GroundOverlay[]>) => {
            const groundOverlays = action.payload;
            state.groundOverlays.push(...groundOverlays);
        },
        removeScreenOverlays: (state, action: PayloadAction<string[]>) => {
            const screenOverlayKeys = action.payload;
            state.screenOverlays = state.screenOverlays.filter(
                (overlay) => !screenOverlayKeys.includes(overlay.key)
            );
        },
        removeAbsoluteOverlays: (state, action: PayloadAction<string[]>) => {
            const absoluteOverlayKeys = action.payload;
            state.absoluteOverlays = state.absoluteOverlays.filter(
                (overlay) => overlay.key && !absoluteOverlayKeys.includes(overlay.key)
            );
        },
        removeGroundOverlays: (state, action: PayloadAction<string[]>) => {
            const groundOverlayKeys = action.payload;
            state.groundOverlays = state.groundOverlays.filter(
                (item) => !groundOverlayKeys.includes(item.key)
            );
        },
        setImageUploaded: (state, action: PayloadAction<boolean>) => {
            state.imageUploaded = action.payload;
        },
        setImageTab: (state, action: PayloadAction<string>) => {
            state.imageTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => imageOverlaysInitialState);
    },
});

export const {
    setImage,
    setClampingType,
    setImageOverlayKey,
    setImageOverlayEdit,
    assignScreenOverlayEdit,
    assignAbsoluteOverlayEdit,
    assignGroundOverlayEdit,
    addScreenOverlay,
    addAbsoluteOverlay,
    addGroundOverlay,
    removeScreenOverlays,
    removeAbsoluteOverlays,
    removeGroundOverlays,
    setImageUploaded,
    setImageTab,
} = imageOverlaysSlice.actions;

export const selectImageOverlayEdit = (state: RootState): ImageOverlayMetadata =>
    state.imageOverlays.imageOverlayEdit;

export const selectScreenOverlays = (state: RootState) => state.imageOverlays.screenOverlays;
export const selectAbsoluteOverlays = (state: RootState) => state.imageOverlays.absoluteOverlays;
export const selectGroundOverlays = (state: RootState) => state.imageOverlays.groundOverlays;
export const selectImageUploaded = (state: RootState) => state.imageOverlays.imageUploaded;
export const selectImageTab = (state: RootState) => state.imageOverlays.imageTab;
export default imageOverlaysSlice.reducer;

export function handleOnAddImage(id: number, title: string, createdDate?: string): AppThunk {
    return (dispatch) => {
        dispatch(setImageOverlayKey(`Image--${nanoid()}`));
        getImageProperties(id).then(({ width, height, href }) => {
            dispatch(
                setImage({
                    imageId: id,
                    title: title,
                    width: width,
                    height: height,
                    href: href,
                    createdDate,
                })
            );
            dispatch(setImageOverlayKey(`Image--${nanoid()}`));
        });
    };
}
