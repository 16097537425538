//TODO: Remove this as we would pull CSP data on demand when user turn on layer
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { MarketViewResultItem } from 'types/CspInputs';
import { MarketViewLayerMetadata } from 'types/Layers/MarketViewLayerMetadata';
import type { LoadStatus } from 'types/LoadStatus';
import { clearPresentation } from './presentationSlice';

interface MarketViewLegend {
    title: string;
    metadata: MarketViewLayerMetadata;
}

interface MarketViewsSliceState {
    views?: MarketViewResultItem[];
    selectField?: string;
    status: LoadStatus;
    legend?: MarketViewLegend;
}

const initialState: MarketViewsSliceState = {
    status: 'idle',
};

export const marketViewsSlice = createSlice({
    name: 'marketViews',
    initialState,
    reducers: {
        setMarketView(state, action: PayloadAction<MarketViewResultItem[] | undefined>) {
            const { payload } = action;
            state.views = payload;
        },
        setSelectField(state, action: PayloadAction<string | undefined>) {
            const { payload } = action;
            state.selectField = payload;
        },
        setMarketViewLegend(state, action: PayloadAction<MarketViewLegend | undefined>) {
            const { payload } = action;
            state.legend = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectMarketViews = (state: RootState): MarketViewResultItem[] | undefined =>
    state.marketViews.views;

export const selectMarketViewField = (state: RootState): string | undefined =>
    state.marketViews.selectField;

export const { setSelectField, setMarketViewLegend, setMarketView } = marketViewsSlice.actions;

export default marketViewsSlice.reducer;
