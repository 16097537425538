import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MappedMarket, MarketDetail, MarketSummary } from 'api/marketApi';
import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import endpoints from 'utils/apiClient/endpoints';

interface MarketListSliceState {
    value?: MarketSummary[];
    detailedList?: MarketDetail[];
    status: LoadStatus;
    marketSphereMarkets?: MappedMarket[];
}

const initialState: MarketListSliceState = {
    status: 'idle',
};

export const fetchAllMarketSummaries = createAsyncThunk('user/fetchMarkets', async () => {
    const response = await endpoints.markets.all.get();
    return (await response.json()) as MarketSummary[];
});

export const fetchMarketDetails = createAsyncThunk('user/fetchMarketsDetails', async () => {
    const response = await endpoints.markets.listDetailed.get();
    return (await response.json()) as MarketDetail[];
});

export const fetchMarketSphereMarkets = createAsyncThunk(
    'user/fetchMarketSphereMarkets',
    async () => {
        const response = await endpoints.markets.marketSphere.get();
        return (await response.json()) as MappedMarket[];
    }
);

export const marketListSlice = createSlice({
    name: 'markets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllMarketSummaries.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllMarketSummaries.rejected, (state) => {
                state.status = 'failed';
                throw new Error('Markets failed to load');
            })
            .addCase(fetchAllMarketSummaries.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            })
            .addCase(fetchMarketDetails.rejected, (state) => {
                state.detailedList = undefined;
                throw new Error('Markets failed to load');
            })
            .addCase(fetchMarketDetails.fulfilled, (state, action) => {
                state.detailedList = action.payload;
            })
            .addCase(fetchMarketSphereMarkets.rejected, (state) => {
                state.marketSphereMarkets = undefined;
                throw new Error('Markets failed to load');
            })
            .addCase(fetchMarketSphereMarkets.fulfilled, (state, action) => {
                state.marketSphereMarkets = action.payload;
            });
    },
});

export const selectMarketList = (state: RootState): MarketSummary[] | null => {
    const markets = state.markets.value;
    if (!markets) return null;
    return markets;
};

export const selectMarketDetailedList = (state: RootState): MarketDetail[] | null => {
    const marketsDetailed = state.markets.detailedList;
    if (!marketsDetailed) return null;
    return marketsDetailed;
};

export const selectMarketSphereMarkets = (state: RootState): MappedMarket[] | null => {
    const marketSphereMarkets = state.markets.marketSphereMarkets;
    if (!marketSphereMarkets) return null;
    return marketSphereMarkets;
};

export default marketListSlice.reducer;
