import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import FeaturedPresentation from 'interfaces/FeaturedPresentation';
import type { LoadStatus } from 'types/LoadStatus';
import endpoints from 'utils/apiClient/endpoints';
import { RootState } from './index';

interface FeaturedPresentationsSliceState {
    value?: FeaturedPresentation[];
    status: LoadStatus;
}

const initialState: FeaturedPresentationsSliceState = {
    status: 'idle',
};

export const fetchFeaturedPresentations = createAsyncThunk(
    'featuredPresentations/fetchFeaturedPresentations',
    async () => {
        const response = await endpoints.presentations.allFeatured.get();
        const result = await response.json();
        return result;
    }
);

export const featuredPresentationsSlice = createSlice({
    name: 'featuredPresentations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeaturedPresentations.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeaturedPresentations.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchFeaturedPresentations.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            });
    },
});

export const selectFeaturedPresentations = (state: RootState) => {
    return state.featuredPresentations.value;
};

export default featuredPresentationsSlice.reducer;
