import { NavigateFunction } from 'react-router-dom';
import { MessageArgsProps, Typography } from '@jll/react-ui-components';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { useAppProps } from 'antd/lib/app/context';
import { NoticeType } from 'antd/lib/message/interface';
import { HookAPI } from 'antd/lib/modal/useModal';

import {
    getDataProvider,
    getPresentation,
    getPresentationClientVersion,
    getPresentationSecurity,
    Presentation,
    PresentationCompare,
    savePresentation as save,
} from 'api/presentationApi';
import { serializeCameraSettings } from 'helpers/cameraHelper';
import { getLibraryLayerSaveState } from 'helpers/libraryLayerHelper';
import {
    createSlideItemSaveState,
    defaultSlideSettings,
    loadSlideItemsFromSaveState,
} from 'helpers/slideHelper';
import { getSignedUrl, uploadImage } from 'services/cloudinaryService';
import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import { logOpenPresentation } from 'utils/analytics/blackbirdActivityLog';
import config from 'utils/config';
import { getCameraSettings } from 'utils/esri/cameraInstanceUtils';
import { takeScreenshot } from 'utils/esri/screenshotUtils';
import { dataUrlToBlob } from 'utils/fileUtils';
import { GoToTarget3DOptions } from '../types/GoToTarget3DOptions';
import LibraryLayerTreeItem from '../types/Layers/LibraryLayerTreeItem';
import { selectLibraryItems } from './libraryItemSlice';
import { loadSlideOnPresentationOpen, selectSlides, setShowSlideName } from './slideSlice';
import { createAppAsyncThunk } from './typedHelpers';

interface PresentationSliceState {
    presentation: Presentation;
    bounds?: unknown;
    status: LoadStatus;
    saving: boolean;
    dataProviderTitle?: string;
}

const initialState: PresentationSliceState = {
    presentation: {
        id: 0,
        presentationName: '',

        marketId: 0,
        markets: [],
        marketSettings: [],

        accessType: 3,
        sharedAccessType: 3,
        isPublished: false,
        groupPermissions: [],
        userPermissions: [],

        createdBy: '',
        createdDate: new Date(),
        modifiedBy: '',
        modifiedDate: new Date(),

        slides: [],
        annotation: '',
        cameraSettings: '',
        propertyComments: [],
        propertyInventorySettings: '',
        propertyReport: '',
        searchColorSettings: '',
        clientVersion: 1,
        slideSettings: defaultSlideSettings,
    } as Presentation,
    status: 'idle',
    saving: false,
};

function getDataProviderTitle(dataProvider?: string): string {
    switch (dataProvider) {
        case 'marketsphere':
            return 'North America';
        default:
            return 'Global';
    }
}

export const loadPresentation = createAsyncThunk<
    Presentation,
    {
        presentationId: number;
        setGoTo: (options: GoToTarget3DOptions) => void;
        slideNumber: string | null;
    },
    { state: RootState }
>(
    'presentation/loadPresentation',
    async ({ presentationId, setGoTo, slideNumber }, { dispatch, getState }) => {
        const { presentation } = await dispatch(fetchPresentation(presentationId)).unwrap();

        logOpenPresentation(presentationId, presentation.marketId);

        dispatch(loadSlideOnPresentationOpen(selectSlides(getState()), setGoTo, slideNumber));
        await dispatch(fetchPresentationPermissions(presentationId));

        dispatch(setShowSlideName(presentation.slideSettings.showSlideName));

        return presentation;
    }
);

export const fetchPresentation = createAsyncThunk(
    'presentation/fetchPresentation',
    async (presentationId: number) => {
        const [presentation, clientVersion, dataProvider] = await Promise.all([
            getPresentation(presentationId),
            getPresentationClientVersion(presentationId),
            getDataProvider(presentationId),
        ]);

        presentation.clientVersion = parseInt(clientVersion ?? 0);
        presentation.dataProvider = dataProvider;

        if (!presentation) throw new Error('Failed to load presentation');

        const presentationMarket = presentation.marketSettings[0];

        if (!presentationMarket) {
            throw new Error('Failed to load presentation market settings');
        }

        return {
            presentation,
            presentationMarket,
            slides: loadSlideItemsFromSaveState(presentation.slides),
        };
    }
);

export const fetchPresentationPermissions = createAsyncThunk(
    'presentation/fetchPresentationPermissions',
    getPresentationSecurity
);

const SAVE_STATE_MESSAGES = {
    SAVING: 'Save in progress...',
    SAVED: 'Presentation saved correctly',
    CANCELLED: 'Save cancelled',
    ERROR: 'Presentation failed to save, please contact blackbird@jll.com if this error continues',
} as const;

export interface SavePresentationProps {
    presentationId: number;
    presentationName: string;
    navigate: NavigateFunction;
    app: useAppProps;
}
export const savePresentation = createAppAsyncThunk(
    'presentation/savePresentation',
    async (
        {
            presentationId,
            presentationName,
            navigate,
            app: { message, modal },
        }: SavePresentationProps,
        { getState, dispatch }
    ) => {
        try {
            if (!(await confirmAdminSpecificSave(modal, getState, presentationId))) {
                message.open(
                    presentationToastMessage({
                        text: SAVE_STATE_MESSAGES.CANCELLED,
                        type: 'warning',
                    })
                );
                return;
            }

            message.open(
                presentationToastMessage({ text: SAVE_STATE_MESSAGES.SAVING, type: 'info' })
            );

            const state = getState();
            const libraryItems = state.libraryItems.libraryItems;
            const layers = libraryItems.map((layer) => getLibraryLayerSaveState(layer));
            const saveState = {
                ...state.presentation.presentation,
                id: presentationId,
                presentationName,
                marketSettings: [{ libraryItems: layers }],
                slides: createSlideItemSaveState(state.slide.slides),
                slideSettings: {
                    showSlideName: state.slide.showSlideName,
                },
                cameraSettings: serializeCameraSettings(getCameraSettings()),
                mapFeatures: {
                    ...state.features.states,
                    basemap: state.basemap.basemapId,
                    buildings: state.features.states.buildings,
                },
            } as Presentation;

            const id = await save(saveState);

            dispatch(setPresentationId(id));
            dispatch(setPresentationName(presentationName));

            const { dataUrl } = await takeScreenshot();
            const blob = dataUrlToBlob(dataUrl);
            const url = await getSignedUrl({
                folder: 'presentation',
                useFilename: true,
            });

            await uploadImage(url, blob, id.toString());

            // It was a new presentation or a save-as, so we will update the URL to the new id
            if (presentationId === 0) {
                navigate(`/presentation/${id}`);
            }
            message.open(
                presentationToastMessage({ text: SAVE_STATE_MESSAGES.SAVED, type: 'success' })
            );
        } catch (e) {
            message.open(
                presentationToastMessage({
                    text: SAVE_STATE_MESSAGES.ERROR,
                    type: 'error',
                })
            );
        }
    }
);

interface PresentationToastMessageProps {
    text: string;
    type: NoticeType;
}
function presentationToastMessage({ text, type }: PresentationToastMessageProps): MessageArgsProps {
    // const presentationMessageStyle: CSSProperties = {
    //     marginLeft: isActionMenuVisible ? '-50px' : '120px',
    // };

    // TODO: Add a style for the message

    const content = <Typography.Text style={{ fontSize: 16 }}>{text}</Typography.Text>;

    return {
        type,
        content,
        duration: 5,
    };
}

async function confirmAdminSpecificSave(
    modal: HookAPI,
    getState: () => RootState,
    presentationId: number
) {
    const state = getState();
    const presentation = selectPresentation(state);

    const isSavingExistingPresentation = presentationId !== 0;
    // If the `accessType` property of `presentation` is 3, then the user is
    // either the owner, or an admin.
    // If the user's "sharedAccessType" is less than 2, the user is not an
    // owner, and would not typically be able to edit the presentation.
    // Together it means the user only has access to edit the presentation
    // because they are an admin user.
    const onlyHasEditAccessAsAdmin =
        presentation.accessType === 3 && presentation.sharedAccessType < 2;

    // If the user is overwriting an existing presentation and they are able
    if (isSavingExistingPresentation && onlyHasEditAccessAsAdmin) {
        return await modal.confirm({
            title: 'Are you sure you want to save?',
            content:
                'Edit rights to this presentation have not been shared with your user, are you sure you want to override this as a superadmin?',
            okText: 'Yes',
            cancelText: 'No',
        });
    }

    return true;
}

export const presentationSlice = createSlice({
    name: 'presentation',
    initialState,
    reducers: {
        setPresentation: (state, action) => {
            state.presentation = action.payload;
        },
        setPresentationId: (state, action) => {
            state.presentation.id = action.payload;
        },
        setPresentationName: (state, action) => {
            state.presentation.presentationName = action.payload;
        },
        clearPresentation: (state) => {
            state.presentation = initialState.presentation;
        },
        setPresentationPublished: (state, action) => {
            state.presentation.isPublished = action.payload;
        },
        setDataProvider: (state, action) => {
            state.presentation.dataProvider = action.payload;
            state.dataProviderTitle = getDataProviderTitle(state.presentation.dataProvider);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPresentation.pending, (state) => {
                state.presentation = initialState.presentation;
                state.status = 'loading';
            })
            .addCase(fetchPresentation.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchPresentation.fulfilled, (state, { payload: { presentation } }) => {
                state.presentation = presentation;
                state.dataProviderTitle = getDataProviderTitle(presentation.dataProvider);
                state.status = 'ready';
            });

        builder
            .addCase(fetchPresentationPermissions.fulfilled, (state, action) => {
                const {
                    accessType,
                    sharedAccessType,
                    isPublished,
                    userPermissions,
                    groupPermissions,
                } = action.payload;
                state.presentation = {
                    ...state.presentation,
                    accessType,
                    sharedAccessType,
                    isPublished,
                    userPermissions,
                    groupPermissions,
                };
            })
            .addCase(fetchPresentationPermissions.rejected, (state) => {
                state.status = 'failed';
                throw new Error('Presentation permissions failed to load');

                // TODO: send user to unauthorized page.
            });

        builder
            .addCase(savePresentation.pending, (state) => {
                state.saving = true;
            })
            .addCase(savePresentation.fulfilled, (state) => {
                state.saving = false;
            })
            .addCase(savePresentation.rejected, (state) => {
                state.saving = false;
            });
    },
});

export const currentPresentationCompareState = (state: RootState): PresentationCompare => {
    const presentationId = state.presentation.presentation.id;
    const presentationName = state.presentation.presentation.presentationName;
    const libraryItems = selectLibraryItems(state) as LibraryLayerTreeItem[];
    const layers = libraryItems.map((layer) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any -- Legacy use of any
        const { checked, ...filteredLayerProps } = getLibraryLayerSaveState(layer) as any;
        return {
            ...filteredLayerProps,
            folderId: -1,
            snapshotView: null,
            active: layer.checked,
        };
    });
    const slides = createSlideItemSaveState(selectSlides(state)).map((slide) => {
        return {
            ...slide,
            presentationId: 0,
        };
    });

    return {
        id: presentationId,
        presentationName: presentationName,
        slides: slides,
        mapFeatures: {
            ...state.features.states,
            basemap: state.basemap.basemapId,
            buildings: { ...state.features.states.buildings, edit: undefined },
        },
        libraryItems: layers,
    } as unknown as PresentationCompare;
};

export const selectPresentation = (state: RootState): Presentation => {
    return state.presentation.presentation;
};

export const selectPresentationName = (state: RootState) =>
    state.presentation.presentation?.presentationName;

export const selectPresentationGroups = (state: RootState) =>
    state.presentation.presentation?.groupPermissions;

export const selectDataProvider = createSelector(
    (state: RootState) => state.presentation.presentation.dataProvider,
    (dataProvider) => dataProvider
);

export const selectDataProviderTitle = (state: RootState) => state.presentation.dataProviderTitle;

/**
 * Using selectors from here instead of components directly referencing the config object
 * since we may want to load these from the presentation object or elsewhere eventually.
 */
export const selectSourceSystem = (_state: RootState) => config.sourceSystem;
export const selectSourceSystemType = (_state: RootState) => config.sourceSystemType;

export const {
    setPresentationId,
    setPresentationName,
    setPresentation,
    clearPresentation,
    setPresentationPublished,
    setDataProvider,
} = presentationSlice.actions;

export default presentationSlice.reducer;
