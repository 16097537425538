import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getPresentationSecurity,
    getPresentationShareStats,
    GroupPermission,
    ShareInfoDtoShareEntry,
} from '../api/presentationApi';
import { RootState } from './index';

interface PresentationShareSliceState {
    presentationShareModalOpen: boolean;
    presentationInternalInviteModalOpen: boolean;
    presentationExternalInviteModalOpen: boolean;
    presentationShareStatistics: ShareInfoDtoShareEntry[];
    presentationShareGroups: GroupPermission[];
}

const initialState: PresentationShareSliceState = {
    presentationShareModalOpen: false,
    presentationInternalInviteModalOpen: false,
    presentationExternalInviteModalOpen: false,
    presentationShareStatistics: [],
    presentationShareGroups: [],
};

export const fetchPresentationShareStatistics = createAsyncThunk(
    'presentationShare/fetchPresentationShareStatistics',
    async (presentationId: number) => getPresentationShareStats(presentationId)
);

export const fetchPresentationShareGroups = createAsyncThunk(
    'presentationShare/fetchPresentationShareGroups',
    async (presentationId: number) => getPresentationSecurity(presentationId)
);
export const presentationShareSlice = createSlice({
    name: 'presentationShare',
    initialState,
    reducers: {
        closePresentationShareModal(state) {
            state.presentationShareModalOpen = false;
        },
        openPresentationShareModal(state) {
            state.presentationShareModalOpen = true;
        },
        closePresentationInternalInviteModal(state) {
            state.presentationInternalInviteModalOpen = false;
        },
        openPresentationInternalInviteModal(state) {
            state.presentationInternalInviteModalOpen = true;
        },
        closePresentationExternalInviteModal(state) {
            state.presentationExternalInviteModalOpen = false;
        },
        openPresentationExternalInviteModal(state) {
            state.presentationExternalInviteModalOpen = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPresentationShareStatistics.fulfilled, (state, action) => {
            state.presentationShareStatistics = action.payload.shares;
        });
        builder.addCase(fetchPresentationShareGroups.fulfilled, (state, action) => {
            state.presentationShareGroups = action.payload.groupPermissions;
        });
    },
});

export const selectPresentationShareModalOpen = (state: RootState) =>
    state.presentationShare.presentationShareModalOpen;

export const selectPresentationInternalInviteModalOpen = (state: RootState) =>
    state.presentationShare.presentationInternalInviteModalOpen;

export const selectPresentationExternalInviteModalOpen = (state: RootState) =>
    state.presentationShare.presentationExternalInviteModalOpen;

export const selectPresentationShareStatistics = (state: RootState) =>
    state.presentationShare.presentationShareStatistics;

export const {
    openPresentationShareModal,
    closePresentationShareModal,
    closePresentationInternalInviteModal,
    openPresentationInternalInviteModal,
    closePresentationExternalInviteModal,
    openPresentationExternalInviteModal,
} = presentationShareSlice.actions;

export default presentationShareSlice.reducer;
