import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

export interface VisibilityRange {
    minScale?: number;
    maxScale?: number;
}

interface MapRangeSlice {
    layerId?: string;
}

const initialState: MapRangeSlice = {
    layerId: undefined,
};

export const mapRangeSlice = createSlice({
    name: 'mapRanges',
    initialState,
    reducers: {
        setVisibilityRangeLayerId: (state, action: PayloadAction<string | undefined>) => {
            state.layerId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectVisibilityRangeLayerId = (state: RootState): string | undefined => {
    return state.mapRangeSlider.layerId;
};

export const { setVisibilityRangeLayerId } = mapRangeSlice.actions;

export default mapRangeSlice.reducer;
