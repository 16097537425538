import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { getSlidesForExport } from 'utils/slideUtils';
import { selectLibraryItemsByKey } from './libraryItemSlice';

interface ExportState {
    exportId: string | null;
    progress: number;
    exportType?: 'PDF' | 'Powerpoint';
}

const initialState: ExportState = {
    exportId: null,
    progress: 0,
};

const exportSlice = createSlice({
    name: 'export',
    initialState,
    reducers: {
        setExportId: (state, action: PayloadAction<string>) => {
            state.exportId = action.payload;
        },
        clearExportId: (state) => {
            state.exportId = null;
            state.exportType = undefined;
        },
        setExportProgress: (state, action: PayloadAction<number>) => {
            state.progress = action.payload;
        },
        setExportType: (state, action: PayloadAction<'PDF' | 'Powerpoint'>) => {
            state.exportType = action.payload;
        },
    },
});

export const { setExportId, clearExportId, setExportProgress, setExportType } = exportSlice.actions;

export const selectExportId = (state: RootState) => {
    return state.export.exportId;
};

export const selectExportProgress = (state: RootState) => {
    return state.export.progress;
};

export const selectSlidesForExport = createSelector(
    (state: RootState) => state.slide.slides,
    selectLibraryItemsByKey,
    (slides, libraryItems) => {
        return getSlidesForExport(slides, libraryItems);
    }
);

export const selectExportTotal = createSelector(selectSlidesForExport, (slides) => {
    return slides.length;
});

export const selectExportType = (state: RootState) => {
    return state.export.exportType;
};

export default exportSlice.reducer;
