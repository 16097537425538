import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './index';

export type DropFileSupportedTypes = 'KML' | 'CSV' | 'IMAGE';

interface MapDragDropOptions {
    dropFile?: File;
}

const initialState: MapDragDropOptions = {};

export const mapDragDropSlice = createSlice({
    name: 'mapDragDropActions',
    initialState,
    reducers: {
        setDragDropItemFile: (state, action: PayloadAction<File | undefined>) => {
            state.dropFile = action.payload;
        },
    },
});

export const selectDragDropOptions = (state: RootState): MapDragDropOptions => {
    return state.dragDrop;
};

export const { setDragDropItemFile } = mapDragDropSlice.actions;

export default mapDragDropSlice.reducer;
