import { createSlice } from '@reduxjs/toolkit';

import { getLibraryItemsByType } from 'helpers/libraryLayerHelper';
import { RootState } from 'store';

interface SelectedSubmarket {
    marketCode: number;
    submarketCode: number;
    propertyType: string;
    submarket: string;
}

interface SubmarketsSliceState {
    selectedSubmarket?: SelectedSubmarket;
}

const initialState: SubmarketsSliceState = {};

export const submarketsSlice = createSlice({
    name: 'submarkets',
    initialState,
    reducers: {
        setSelectedSubmarket(state, action) {
            state.selectedSubmarket = action.payload;
        },
    },
});

export const { setSelectedSubmarket } = submarketsSlice.actions;

export const selectSelectedSubmarket = (state: RootState) => state.submarkets.selectedSubmarket;

export const selectAllSubmarketItems = (state: RootState) => {
    const allSubmarketItems = getLibraryItemsByType(
        state.libraryItems.libraryItems,
        'SubmarketStatistics'
    );
    return allSubmarketItems.filter((item) => item.checked).map((item) => item);
};

export default submarketsSlice.reducer;
