import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { WebsiteOverlayMetadata } from 'types/Layers/WebsiteOverlayMetadata';
import { clearPresentation } from './presentationSlice';

interface WebsiteOverlaysSliceState {
    websiteOverlays: WebsiteOverlayMetadata[];
    websiteOverlayToEdit: WebsiteOverlayMetadata;
}

export const websiteOverlayInitialState: WebsiteOverlaysSliceState = {
    websiteOverlays: [],
    websiteOverlayToEdit: {
        id: '',
        posX: 0,
        posY: 0,
        width: 0,
        height: 0,
        content: '',
        isIframe: false,
        isFullScreen: false,
        isEditing: false,
    } as WebsiteOverlayMetadata,
};

export const WebsiteOverlaysSlice = createSlice({
    name: 'websiteOverlays',
    initialState: websiteOverlayInitialState,
    reducers: {
        setWebsiteOverlayToEdit: (state, action: PayloadAction<WebsiteOverlayMetadata>) => {
            state.websiteOverlayToEdit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => websiteOverlayInitialState);
    },
});

export const { setWebsiteOverlayToEdit } = WebsiteOverlaysSlice.actions;

export const selectWebsiteOverlayToEdit = (state: RootState): WebsiteOverlayMetadata => {
    return state.websiteOverlays.websiteOverlayToEdit;
};

export default WebsiteOverlaysSlice.reducer;
