import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';
import { restrictStates } from './stateMachineHelpers';

export interface HighlightPinReference {
    type: 'highlight-pin-reference';
    displayName: string;
    layerId: string;
    objectId: number;
    highlightLayerKey: string;
    highlightItemKey: string;
}

export interface CsvPinReference {
    type: 'csv-pin-reference';
    csvLayerTreeItemKey: string;
    index: number;
}

export type PinReference = HighlightPinReference | CsvPinReference;

interface IdleState {
    stateName: 'idle';
    errorMessage?: string;
}

interface MovingState {
    stateName: 'moving';
    pinReference: PinReference;
    messageReference: string;
}

interface SelectedState {
    stateName: 'selected';
    pinReference: PinReference;
}

type MovePinSliceState = IdleState | MovingState | SelectedState;

const INITIAL_STATE: MovePinSliceState = Object.freeze({ stateName: 'idle' });

export const movePinSlice = createSlice({
    name: 'movePins',
    initialState: INITIAL_STATE as MovePinSliceState,
    reducers: {
        selectOsmPin(_state, action: PayloadAction<Omit<HighlightPinReference, 'type'>>) {
            return {
                stateName: 'selected',
                pinReference: { ...action.payload, type: 'highlight-pin-reference' },
            };
        },
        selectCsvPin(_state, action: PayloadAction<Omit<CsvPinReference, 'type'>>) {
            return {
                stateName: 'selected',
                pinReference: { ...action.payload, type: 'csv-pin-reference' },
            };
        },
        startMovingPin(state, action: PayloadAction<{ loadingMessageKey: string }>) {
            return restrictStates(
                ['selected'],
                state,
                (state): MovingState => ({
                    stateName: 'moving',
                    pinReference: state.pinReference,
                    messageReference: action.payload.loadingMessageKey,
                }),
                (): IdleState => ({ stateName: 'idle', errorMessage: 'No pin selected' })
            );
        },
        exitMove() {
            return INITIAL_STATE;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => INITIAL_STATE);
    },
});

const selectMovePinState = (state: RootState) => state.movePin;
export const selectSelectedMovePin = createSelector(
    selectMovePinState,
    (state): SelectedState | undefined => (state.stateName === 'selected' ? state : undefined)
);
export const selectActiveMovePin = createSelector(
    selectMovePinState,
    (state): MovingState | undefined => (state.stateName === 'moving' ? state : undefined)
);

export const { selectCsvPin, selectOsmPin, startMovingPin, exitMove } = movePinSlice.actions;

export default movePinSlice.reducer;
