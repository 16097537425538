import { CloudinaryUploadResult } from 'types/CloudinaryTypes';
import endpoints from 'utils/apiClient/endpoints';

export interface CloudinarySignData {
    apiKey: string;
    cloudName: string;
    folder: string;
    signature: string;
    timestamp: number;
}

export interface CloudinarySignOptions {
    folder: string;
    useFilename?: boolean;
    useCache?: boolean;
}

export const getSignedUrl = async (options: CloudinarySignOptions) => {
    const { useFilename } = options;
    const { apiKey, folder, signature, cloudName, timestamp } = await getSignature(options);

    const params = new URLSearchParams();
    params.append('api_key', apiKey);
    params.append('folder', folder);
    params.append('signature', signature);
    params.append('timestamp', timestamp.toString());

    if (useFilename === true) {
        params.append('unique_filename', 'false');
        params.append('use_filename', 'true');
    }

    return `https://api.cloudinary.com/v1_1/${cloudName}/image/upload?${params.toString()}`;
};

export const getSignature = async ({
    folder,
    useFilename,
    useCache = true,
}: CloudinarySignOptions): Promise<CloudinarySignData> => {
    if (useCache) {
        const cachedSignature = getSignatureFromCache(folder);
        if (cachedSignature) {
            return cachedSignature;
        }
    }

    const response = await endpoints.cloudinary.signature.get({
        queryParameters: {
            folder,
            ...(useFilename && { useFilename: true }),
        },
    });

    if (!response.ok) {
        throw new Error(`Error getting signature: ${response.status} ${response.statusText}`);
    }

    const signature = await response.json();

    if (useCache) {
        // Cache the signature in local storage
        localStorage.setItem(`cloudinary-signature-${folder}`, JSON.stringify(signature));
    }

    return await signature;
};

export const uploadImage = async (
    url: string,
    image: Blob,
    filename?: string
): Promise<CloudinaryUploadResult> => {
    const formData = new FormData();
    formData.append('file', image, filename);

    const response = await fetch(url, {
        method: 'POST',
        body: formData,
    });

    if (!response.ok) {
        throw new Error(`Error uploading image: ${response.status} ${response.statusText}`);
    }

    return await response.json();
};

export const getSignatureFromCache = (folder: string): CloudinarySignData | null => {
    const key = `cloudinary-signature-${folder}`;
    const cachedSignature = localStorage.getItem(key);
    const signature = cachedSignature && (JSON.parse(cachedSignature) as CloudinarySignData);
    if (signature) {
        // Get current seconds since epoch
        const now = Math.floor(Date.now() / 1000);
        // If signature is older than 55 minutes, it's expired
        if (!signature.signature || now - signature.timestamp > 55 * 60) {
            localStorage.removeItem(key);
            return null;
        }

        return signature;
    }

    return null;
};
