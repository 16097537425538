import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

export interface WeatherState {
    type: string;
    cloudCover: number;
    precipitation?: number;
    snowCover?: boolean;
    fogStrength?: number;
}

export interface WeatherSettings {
    state: WeatherState;
    isInstant?: boolean;
}

export const defaultWeatherSettings: WeatherState = {
    type: 'sunny',
    cloudCover: 0,
    fogStrength: 0,
    precipitation: 0,
    snowCover: false,
};
const initialState: WeatherSettings = {
    state: defaultWeatherSettings,
};

export const weatherWidgetSlice = createSlice({
    name: 'weatherWidget',
    initialState: initialState,
    reducers: {
        setWeatherWidgetInstant: (state, action: PayloadAction<boolean>) => {
            state.isInstant = action.payload;
        },
        setWeatherState: (state, action: PayloadAction<WeatherSettings>) => {
            const { state: settings, isInstant } = action.payload;
            state.state = settings;
            state.isInstant = isInstant;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectWeatherWidgetSettings = (state: RootState): WeatherSettings => {
    return { state: state.weather.state, isInstant: state.weather.isInstant };
};

export const { setWeatherState, setWeatherWidgetInstant } = weatherWidgetSlice.actions;

export default weatherWidgetSlice.reducer;
