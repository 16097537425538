import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { selectPresentationModeEnabled } from './applicationModeSlice';
import { selectActivePreviewTableKey } from './libraryItemSlice';
import { RightPanelItem } from './rightPanelSlice';
import { selectIsClientUser } from './userSlice';

export const selectRightPanelStack = (state: RootState): RightPanelItem[] => {
    return state.rightPanel.panelStack;
};

export const selectActiveRightPanel = (state: RootState): RightPanelItem | null => {
    return state.rightPanel.panelStack[state.rightPanel.panelStack.length - 1] ?? null;
};

export const selectActiveRightPanelKey = createSelector([selectActiveRightPanel], (panel) => {
    if (!panel) return null;
    return panel.name;
});

export const selectShowRightPanel = createSelector(
    [
        selectPresentationModeEnabled,
        selectActiveRightPanel,
        selectActivePreviewTableKey,
        selectIsClientUser,
    ],
    (isPresentationModeEnabled, activePanel, activePreviewTableKey, isClientUser) => {
        // If there is an active preview table key we always show the panel, since it shows in both presentation mode and client share
        if (activePreviewTableKey) {
            return true;
        }

        // If there is no active right panel we don't show anything
        if (!activePanel) {
            return false;
        }

        // If the panel is not visible in presentation mode and we are in presentation mode, we don't show it
        if (isPresentationModeEnabled && !activePanel?.visibleInPresentationMode) {
            return false;
        }

        // If the panel is hidden in client share and we are a client user, we don't show it
        if (isClientUser && activePanel?.hiddenInClientShare) {
            return true;
        }

        return activePanel;
    }
);
