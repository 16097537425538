import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';

import { selectOptionalCurrentUser } from 'store/userSlice';
import { useAppSelector } from 'types/hooks';
import config from 'utils/config';
import { _setClient } from 'utils/launchDarkly';
import { useAuth } from './AuthProvider';

const IsLdIdentifiedProvider = createContext(false);

interface LDProviderProps {
    children: ReactNode;
}
function LDProvider({ children }: LDProviderProps) {
    const ldClient = useLDClient();
    const auth = useAuth();
    const user = useAppSelector(selectOptionalCurrentUser);
    const [isLdIdentified, setIsLdIdentified] = useState(false);

    useEffect(() => {
        if (ldClient) {
            _setClient(ldClient);
        }
    }, [ldClient]);

    useEffect(() => {
        if (!ldClient || !user) {
            return;
        }

        setIsLdIdentified(true);

        ldClient?.identify({
            key: user.userPrincipalName.toLowerCase(),
            email: user.email.toLowerCase(),
            name: user.displayName,
            firstName: user.displayName.split(' ').shift(),
            lastName: user.displayName.split(' ').pop(),
        });
    }, [ldClient, auth, user]);

    return (
        <IsLdIdentifiedProvider.Provider value={isLdIdentified}>
            {children}
        </IsLdIdentifiedProvider.Provider>
    );
}

export function useIsLdIdentified() {
    return useContext(IsLdIdentifiedProvider);
}

export default withLDProvider<LDProviderProps & JSX.IntrinsicAttributes>({
    clientSideID: config.launchDarklyClientId,
})(LDProvider);
