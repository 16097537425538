import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

interface ApplicationModeState {
    initialized: boolean;
    presentationMode: boolean;
}

const INITIAL_STATE: ApplicationModeState = {
    initialized: false,
    presentationMode: false,
};

const applicationModeSlice = createSlice({
    name: 'applicationMode',
    initialState: INITIAL_STATE,
    reducers: {
        enterPresentationMode(state) {
            state.presentationMode = true;
        },
        exitPresentationMode(state) {
            state.presentationMode = false;
        },
        initializeFromSearchParams(
            state,
            { payload: { searchParams } }: PayloadAction<{ searchParams: Record<string, unknown> }>
        ) {
            if (state.initialized) {
                console.warn('Attempting to reinitialize app state');
            }

            state.initialized = true;
            if ('presentationMode' in searchParams) {
                state.presentationMode = true;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => INITIAL_STATE);
    },
});

export const { enterPresentationMode, exitPresentationMode, initializeFromSearchParams } =
    applicationModeSlice.actions;
export default applicationModeSlice.reducer;

export function selectPresentationModeEnabled(state: RootState): boolean {
    return state.applicationMode.presentationMode;
}
