import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk, RootState } from 'store';
import { clearPresentation } from './presentationSlice';

type LibraryItemActionType = 'renaming' | 'removing';

interface LibraryItemAction {
    libraryItemKey: string;
    action?: LibraryItemActionType;
}

interface LibraryItemActionsSlice {
    libraryItemAction: LibraryItemAction | null;
}

const initialState: LibraryItemActionsSlice = {
    libraryItemAction: null,
};

export const libraryItemActions = createSlice({
    name: 'libraryItemActions',
    initialState,
    reducers: {
        setAction: (
            state,
            action: PayloadAction<{ key: string; actionType: LibraryItemActionType | undefined }>
        ) => {
            const { key, actionType } = action.payload;
            state.libraryItemAction = {
                libraryItemKey: key,
                action: actionType,
            };
        },

        clearLibraryItemAction: (state) => {
            state.libraryItemAction = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const renameAction =
    (key: string): AppThunk =>
    (dispatch) => {
        dispatch(setAction({ key, actionType: 'renaming' }));
    };

export const removeAction =
    (key: string): AppThunk =>
    (dispatch) => {
        dispatch(setAction({ key, actionType: 'removing' }));
    };

export const { clearLibraryItemAction, setAction } = libraryItemActions.actions;

const getKeyForActionType = (state: RootState, actionType: LibraryItemActionType) => {
    const libraryItemAction = state.libraryItemActions.libraryItemAction;
    const action = libraryItemAction?.action;

    if (!action || action !== actionType) return null;

    return libraryItemAction.libraryItemKey;
};

export const selectRenamingLibraryKey = (state: RootState) =>
    getKeyForActionType(state, 'renaming');

export const selectRemovingLibraryKey = (state: RootState) =>
    getKeyForActionType(state, 'removing');

export default libraryItemActions.reducer;
