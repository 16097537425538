function isOneOf<S extends string>(validStates: S[], state: string): state is S {
    return (validStates as string[]).includes(state);
}

export function restrictStates<
    S extends { stateName: string },
    SN extends S['stateName'],
    TThunk extends (matchState: S & { stateName: SN }) => unknown,
    TElse extends ((matchState: Exclude<S, { stateName: SN }>) => unknown) | undefined = undefined
>(
    states: SN[],
    matchState: S,
    thunk: TThunk,
    elseThunk?: TElse
):
    | ReturnType<TThunk>
    | (TElse extends (matchState: Exclude<S, { stateName: SN }>) => unknown
          ? ReturnType<TElse>
          : undefined) {
    if (isOneOf(states, matchState.stateName)) {
        return thunk(matchState as S & { stateName: SN }) as ReturnType<TThunk>;
    } else {
        logInvalidTransition(matchState.stateName, 'selectMatch');
        if (elseThunk) {
            return elseThunk(matchState as Exclude<S, { stateName: SN }>) as TElse extends (
                matchState: Exclude<S, { stateName: SN }>
            ) => unknown
                ? ReturnType<TElse>
                : undefined;
        } else {
            return undefined as TElse extends (matchState: Exclude<S, { stateName: SN }>) => unknown
                ? ReturnType<TElse>
                : undefined;
        }
    }
}
function logInvalidTransition(currentState: string | number | symbol, action: string) {
    console.error(
        `Invalid state transition (currentState: '${String(currentState)}' ; action: '${action})'`
    );
}
