import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LoadStatus } from 'types/LoadStatus';
import UserSettings from 'types/Users/UserSettings';
import endpoints from 'utils/apiClient/endpoints';
import { RootState } from './index';

interface UserSettingsSliceState {
    value?: UserSettings;
    status: LoadStatus;
}

const initialState: UserSettingsSliceState = {
    status: 'idle',
};

export const fetchUserSettings = createAsyncThunk('userSettings/fetchUserSettings', async () => {
    const response = await endpoints.users.settings.get();
    return await response.json();
});

export const userSettingsSlice = createSlice({
    name: 'userSettings',
    initialState,
    reducers: {
        setOnboardingComplete: (state, value) => {
            if (!state.value) return;
            state.value.preferences.onboardingComplete = value.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserSettings.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserSettings.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchUserSettings.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            });
    },
});

export const selectOnboardingComplete = (state: RootState) =>
    state.userSettings.value?.preferences?.onboardingComplete ?? false;

export default userSettingsSlice.reducer;
