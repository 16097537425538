import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CsvLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';

interface CsvLayerToHighlightSetSliceState {
    csvLayer: CsvLayerTreeItem | null;
}

const initialState: CsvLayerToHighlightSetSliceState = {
    csvLayer: null,
};

export const csvLayerToHighlightSetSlice = createSlice({
    name: 'csvStyle',
    initialState,
    reducers: {
        setCsvLayer: (state, { payload }: { payload: CsvLayerTreeItem | null }) => {
            state.csvLayer = payload;
        },
    },
});

export const { setCsvLayer } = csvLayerToHighlightSetSlice.actions;

export const selectCsvLayer = (state: RootState): CsvLayerTreeItem | null => {
    return state.csvLayerToHighlightSet.csvLayer;
};

export default csvLayerToHighlightSetSlice.reducer;
