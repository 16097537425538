import { Cloudinary } from '@cloudinary/url-gen';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { Configuration } from 'types/Configuration';
import type { LoadStatus } from 'types/LoadStatus';
import endpoints from 'utils/apiClient/endpoints';

interface AppConfigSliceState {
    value?: Configuration;
    status: LoadStatus;
}

const initialState: AppConfigSliceState = {
    status: 'idle',
};

export const fetchAppConfig = createAsyncThunk('configuration/fetchConfiguration', async () => {
    const response = await endpoints.configuration.get();
    const result = await response.json();
    return result;
});

export const AppConfigSlice = createSlice({
    name: 'appConfig',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppConfig.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAppConfig.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(fetchAppConfig.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            });
    },
});

export const selectAppConfig = (state: RootState) => {
    const config = state.appConfig.value;
    if (!config) throw new Error('No configuration loaded');
    return config;
};

export interface CloudinaryCloudConfig {
    cloudinary: Cloudinary;
    basePath?: string;
}
export const selectCloudinaryCloud = createSelector(
    (state: RootState) => state.appConfig?.value,
    (configuration): CloudinaryCloudConfig => {
        return {
            cloudinary: new Cloudinary({
                cloud: {
                    cloudName: configuration?.cloudinaryName,
                },
            }),
            basePath: configuration?.cloudinaryFolder,
        };
    }
);

export default AppConfigSlice.reducer;
