import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation, fetchPresentation } from './presentationSlice';

interface BasemapSliceState {
    basemapId: string;
}

const initialState: BasemapSliceState = {
    basemapId: 'topo-vector',
};

export const basemapSlice = createSlice({
    name: 'basemap',
    initialState,
    reducers: {
        setBasemapId: (state, action) => {
            state.basemapId = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);

        builder
            .addCase(fetchPresentation.pending, () => initialState)
            .addCase(fetchPresentation.fulfilled, (state, { payload: { presentation } }) => {
                if (presentation.mapFeatures?.basemap) {
                    state.basemapId = presentation.mapFeatures.basemap;
                }
            });
    },
});

export const selectBasemapId = (state: RootState): string => state.basemap.basemapId;

export const { setBasemapId } = basemapSlice.actions;

export default basemapSlice.reducer;
