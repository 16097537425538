import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ConfigProvider, theme } from '@jll/react-ui-components';
import { merge } from 'lodash';

const ThemeContext = createContext({
    isDarkTheme: false,
    toggleTheme: () => {},
    setLightTheme: () => {},
    setDarkTheme: () => {},
});

const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    const commonThemeTokens = {
        token: {
            borderRadiusLG: 8,
            borderRadiusSM: 4,
            colorPrimary: '#2a2d31',
            colorLink: '#1890ff',
            fontFamily: 'Source Sans Pro, sans-serif',
            controlHeightXS: 16,
        },
        components: {
            Button: {
                onlyIconSize: 20,
                onlyIconSizeLG: 20,
                onlyIconSizeSM: 20,
            },
            Tabs: {
                colorPrimary: '#40798D',
                colorPrimaryHover: '#40798D',
            },
            Input: {
                activeBorderColor: '#09779E',
                activeShadow: 'none',
            },
        },
    };

    const lightTheme = merge({}, commonThemeTokens, {
        algorithm: theme.defaultAlgorithm,
        token: {
            colorBgLayout: '#f6f7f7',
            colorBgContainer: '#fff',
            colorBgBase: '#fff',
        },
        components: {
            Button: {
                colorTextLightSolid: '#fff',
            },
            Switch: {
                colorPrimary: 'rgb(12 123 161 / 100%)',
                fontSize: 12,
            },
            Badge: {
                colorPrimary: '#0c7ba1',
            },
        },
    });

    const darkTheme = merge({}, commonThemeTokens, {
        algorithm: theme.darkAlgorithm,
        token: {
            colorBgLayout: '#01151c',
            colorBgContainer: '#01151c',
            colorBgBase: '#01151c',
        },
        components: {
            Button: {
                onlyIconSize: 20,
                onlyIconSizeLG: 20,
                onlyIconSizeSM: 20,
            },
            Switch: {
                colorPrimary: 'rgb(12 123 161 / 100%)',
                fontSize: 12,
            },
            Badge: {
                colorPrimary: '#0c7ba1',
            },
        },
    });

    const loadEsriTheme = (theme: string) => {
        const link = document.createElement('link');
        link.href = `https://js.arcgis.com/4.28/@arcgis/core/assets/esri/themes/${theme}/main.css`;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        document.head.append(link);

        const oldLink = document.getElementById('arcgis-theme');
        if (oldLink) {
            oldLink.remove();
        }

        link.id = 'arcgis-theme';
    };

    useEffect(() => {
        loadEsriTheme(isDarkTheme ? 'dark' : 'light');

        const style = document.createElement('style');

        const existingStyle = document.getElementById('theme-variables');
        if (existingStyle) {
            existingStyle.textContent = style.textContent;
        } else {
            style.id = 'theme-variables';
            document.head.appendChild(style);
        }
    }, [isDarkTheme]);

    useEffect(() => {
        if (isDarkTheme) {
            document.body.classList.add('calcite-mode-dark');
        } else {
            document.body.classList.remove('calcite-mode-dark');
        }

        const widgets = document.getElementsByClassName('esri-ui');
        for (let i = 0; i < widgets.length; i++) {
            if (isDarkTheme) {
                widgets[i]?.classList.add('calcite-mode-dark');
                widgets[i]?.classList.remove('calcite-mode-light');
            } else {
                widgets[i]?.classList.remove('calcite-mode-dark');
                widgets[i]?.classList.add('calcite-mode-light');
            }
        }
    }, [isDarkTheme]);

    const toggleTheme = () => setIsDarkTheme(!isDarkTheme);

    const setLightTheme = () => setIsDarkTheme(false);
    const setDarkTheme = () => setIsDarkTheme(true);

    return (
        <ThemeContext.Provider value={{ isDarkTheme, toggleTheme, setLightTheme, setDarkTheme }}>
            <ConfigProvider theme={isDarkTheme ? darkTheme : lightTheme}>{children}</ConfigProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeChanger = () => useContext(ThemeContext);

export default ThemeProvider;
