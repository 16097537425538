import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import type { LoadStatus } from 'types/LoadStatus';
import User from 'types/Users/User';
import endpoints from 'utils/apiClient/endpoints';

interface UserSliceState {
    value?: User;
    status: LoadStatus;
}

const initialState: UserSliceState = {
    status: 'idle',
};

export const fetchCurrentUser = createAsyncThunk('user/fetchUser', async () => {
    const userResult = await endpoints.users.current.get();
    const user = await userResult.json();

    return user;
});

export const configurationSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCurrentUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCurrentUser.rejected, (state) => {
                throw new Error('User failed to load');
                state.status = 'failed';
            })
            .addCase(fetchCurrentUser.fulfilled, (state, action) => {
                state.status = 'ready';
                state.value = action.payload;
            });
    },
});

export const selectCurrentUser = (state: RootState): User => {
    const user = state.user.value;
    if (!user) throw new Error('User is undefined');
    return user;
};

export const selectOptionalCurrentUser = (state: RootState): User | undefined => {
    return state.user.value;
};

export const selectIsAdminUser = (state: RootState): boolean => {
    const user = state.user.value;
    return typeof user !== 'undefined' && (user.isAdmin || user.marketRoles.length > 0);
};

export const selectIsClientUser = (state: RootState) =>
    state.user.value?.blackbirdRoles.includes('BlackBird Secure Clientshare') ?? false;

export default configurationSlice.reducer;
