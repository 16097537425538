import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

export interface DirectLineMeasurement3DWidgetSlice {
    visible: boolean;
}

const initialState: DirectLineMeasurement3DWidgetSlice = {
    visible: false,
};

export const directLineMeasurement3DWidgetSlice = createSlice({
    name: 'directLineMeasurement3DWidget',
    initialState,
    reducers: {
        setShowDirectLineMeasurement3DWidget: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectShowDirectLineMeasurement3DWidget = (state: RootState): boolean => {
    return state.directLineMeasurement3DWidget.visible;
};

export const { setShowDirectLineMeasurement3DWidget } = directLineMeasurement3DWidgetSlice.actions;

export default directLineMeasurement3DWidgetSlice.reducer;
