import { createListenerMiddleware, Dispatch, TypedStartListening } from '@reduxjs/toolkit';

import { SystemType } from 'components/Chat/systems/systemTypes';
import { RootState } from 'store';
import { selectAssistantVisible, setQuickActions, setSystemType } from './assistantSlice';
import { setDefaultValues, setShowResults } from './searchSlice';

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as TypedStartListening<RootState>;

const cleanupActions: Partial<Record<SystemType, (dispatch: Dispatch) => void>> = {
    [SystemType.Search]: (dispatch) => {
        dispatch(setShowResults(false));
        dispatch(setDefaultValues({}));
        dispatch(setQuickActions([]));
    },
};

startAppListening({
    predicate: (_, currentState, previousState) => {
        return selectAssistantVisible(currentState) !== selectAssistantVisible(previousState);
    },
    effect: async (_, listenerApi) => {
        const assistantVisible = selectAssistantVisible(listenerApi.getState());
        if (!assistantVisible) {
            Object.values(cleanupActions).forEach((cleanup) => cleanup(listenerApi.dispatch));
            listenerApi.dispatch(setSystemType(undefined));
        }
    },
});

export const assistantCleanupListenerMiddleware = listenerMiddleware.middleware;
