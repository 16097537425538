import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { CsvLayerMetadata } from 'types/Layers/CsvLayerMetadata';
import { OsmHighlightSetBuilding } from 'types/Layers/OsmHighlightSetMetadata';

export interface TableHighlightSetBuilding {
    csvLayerId: number;
    displayFields: string[];
    name: string;
    csvItemIndex: number;
    isMarketSurvey: boolean;
}

interface TableHighlightSetSliceState {
    selectedBuilding: TableHighlightSetBuilding | null;
}

const initialState: TableHighlightSetSliceState = {
    selectedBuilding: null,
};

export const tableHighlightSetSlice = createSlice({
    name: 'tableHighlightSet',
    initialState,
    reducers: {
        setSelectedT2HBuilding(
            state,
            action: PayloadAction<{
                csvLayerMetadata: Omit<CsvLayerMetadata, 'styles'> | undefined;
                building: OsmHighlightSetBuilding;
            }>
        ) {
            const { csvLayerMetadata, building } = action.payload;

            state.selectedBuilding = {
                csvLayerId: csvLayerMetadata?.layerId as number,
                csvItemIndex: Number(building.extraId),
                name: building.name as string,
                displayFields: csvLayerMetadata?.displayFields as string[],
                isMarketSurvey: !!csvLayerMetadata?.marketSurvey,
            };
        },
        clearSelectedT2HBuilding(state) {
            state.selectedBuilding = null;
        },
        closeMarketSurveyPanel(state) {
            state.selectedBuilding = null;
        },
    },
});

export const { setSelectedT2HBuilding, clearSelectedT2HBuilding, closeMarketSurveyPanel } =
    tableHighlightSetSlice.actions;

export const selectSelectedBuilding = (state: RootState): TableHighlightSetBuilding | null => {
    return state.tableHighlightSet.selectedBuilding;
};

export default tableHighlightSetSlice.reducer;
