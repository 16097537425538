import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { Modal } from '@jll/react-ui-components';
import { AccessToken, OktaAuth, SignoutOptions, Token } from '@okta/okta-auth-js';

import AuthSessionRefreshModal from 'hooks/AuthSessionRefreshModal';
import { logLogin } from 'utils/analytics/blackbirdActivityLog';
import {
    addOnUnauthenticatedHook,
    setOktaAuthInstance,
    setTokens,
} from 'utils/apiClient/ApiEndpoint';
import { convertBase64AdGuid, loadClientSession } from 'utils/auth/clientAuthHelpers';
import config from 'utils/config';

export enum AuthType {
    None,
    ShareTokenAuth,
    OAuthTokenAuth,
}

interface AuthContextInterface {
    logIn: () => void;
    signOut: (options: SignoutOptions) => Promise<void>;
    isAuthenticated: boolean | undefined;
    authType: AuthType;
    setDatadogRumUser: () => void;
}

const oktaAuth = new OktaAuth({
    issuer: `${config.oktaDomain}/oauth2/${config.oktaAuthorizationServerId}`,
    clientId: config.oktaClientId,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    responseMode: 'fragment',
    tokenManager: {
        storage: 'localStorage',
        autoRenew: true,
    },
    scopes: ['openid', 'email', 'profile', 'offline_access'],
    restoreOriginalUri: async (_oktaAuth, originalUri: string | undefined) => {
        if (originalUri === undefined) throw new Error();

        await setTokens(oktaAuth);

        try {
            await logLogin();
        } catch (e) {
            console.log(e);
        }

        window.location.replace(originalUri);
    },
});

const AuthContext = createContext<AuthContextInterface>({
    isAuthenticated: undefined,
    logIn: () => console.error('AuthProvider used outside of AuthContext'),
    signOut: async () => console.error('AuthProvider used outside of AuthContext'),
    authType: AuthType.None,
    setDatadogRumUser: async () => {},
});

type Props = {
    children?: ReactNode;
};

export const AuthProvider = ({ children }: Props): JSX.Element | null => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
    const loginTriggered = useRef(false);
    const [refreshSessionModalOpen, setRefreshSessionModalOpen] = useState(false);

    const shareTokenAuth = useMemo(() => loadClientSession(showRenewAccessMessage), []);

    const errorHandler = useCallback(() => {
        setRefreshSessionModalOpen(true);
    }, [setRefreshSessionModalOpen]);

    const initializationPromise = useMemo(async () => {
        await initializeAuth(setIsAuthenticated, shareTokenAuth);

        oktaAuth.tokenManager.on('renewed', handleTokenRenewal);
        oktaAuth.tokenManager.on('error', errorHandler);
        addOnUnauthenticatedHook(errorHandler);
        setOktaAuthInstance(oktaAuth);
    }, [errorHandler, shareTokenAuth]);

    const logIn = useCallback(async () => {
        if (loginTriggered.current) {
            return;
        }
        loginTriggered.current = true;
        await initializationPromise;

        oktaAuth.setOriginalUri(window.location.href);
        await oktaAuth.signInWithRedirect();
    }, [initializationPromise]);

    const signOut = useCallback(
        async (options: SignoutOptions) => {
            if (shareTokenAuth) {
                shareTokenAuth.signOut();
            } else {
                await oktaAuth.signOut(options);
            }
        },
        [shareTokenAuth]
    );

    const setDatadogRumUser = async () => {
        datadogRum.clearUser();
        const tokens = await oktaAuth.tokenManager.getTokens();
        const idToken = tokens.idToken;

        if (!idToken) return;
        const { ADGUID: adGuidBase64, email } = idToken.claims;

        datadogRum.setUser({
            id: convertBase64AdGuid(adGuidBase64),
            email,
        });
    };

    return (
        <AuthContext.Provider
            value={{
                logIn,
                isAuthenticated: isAuthenticated,
                signOut: signOut,
                authType: getAuthType(isAuthenticated, shareTokenAuth),
                setDatadogRumUser,
            }}
        >
            {children}
            <AuthSessionRefreshModal
                authClient={oktaAuth}
                open={refreshSessionModalOpen}
                setClose={() => setRefreshSessionModalOpen(false)}
            />
        </AuthContext.Provider>
    );
};

function getAuthType(isAuthenticated: boolean | undefined, shareTokenAuth: unknown): AuthType {
    if (!isAuthenticated) {
        return AuthType.None;
    } else if (shareTokenAuth) {
        return AuthType.ShareTokenAuth;
    } else {
        return AuthType.OAuthTokenAuth;
    }
}

function showRenewAccessMessage(): Promise<number> {
    return new Promise((resolve) => {
        Modal.info({
            title: 'Please, renew your access',
            content: (
                <p>
                    Your access has expired. Please, click in the link below to renew your access.
                </p>
            ),
            okText: 'Renew Access',
            onOk: () => resolve(1),
        });
    });
}

async function initializeAuth(
    setIsAuthenticated: (value: boolean) => void,
    shareTokenAuth: ReturnType<typeof loadClientSession>
): Promise<void> {
    if (shareTokenAuth) {
        config.accessToken = shareTokenAuth.accessToken;
        setIsAuthenticated(true);
        return;
    }
    if (oktaAuth.isLoginRedirect()) {
        await oktaAuth.handleLoginRedirect();
        return;
    } else if (await oktaAuth.isAuthenticated()) {
        await handleAuthenticatedUser();
        setIsAuthenticated(true);
    } else {
        setIsAuthenticated(false);
    }
}

async function handleAuthenticatedUser(): Promise<void> {
    await setTokens(oktaAuth);
    oktaAuth.start();

    return;
}

function handleTokenRenewal(key: string, newToken: Token): void {
    if (key === 'accessToken') {
        const accessToken = newToken as AccessToken;
        if (accessToken === undefined) {
            throw new Error('Invalid token');
        }

        config.accessToken = accessToken.accessToken;
    }
}

export const useAuth = () => useContext(AuthContext);
