import Point from '@arcgis/core/geometry/Point';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';
import { clearPresentation } from './presentationSlice';

export interface PointThing {
    point: Point;
    icon: string;
}

interface PointSliceState {
    points: PointThing[];
}

const initialState: PointSliceState = {
    points: [],
};

export const pointsSlice = createSlice({
    name: 'points',
    initialState,
    reducers: {
        setPoints: (state, action) => {
            state.points = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const selectPoints = (state: RootState) => state.points.points;

export const { setPoints } = pointsSlice.actions;

export default pointsSlice.reducer;
