import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { find } from 'lodash';

import {
    defaultHighlightSetStyles,
    HighlightSetStyleProperties,
    HighlightSetType,
} from 'helpers/highlightSetHelper';
import {
    FloorHighlightSetProps,
    HighlightFloorsProps,
    HighlightSetProperty,
    HighlightSetProps,
} from 'types/Layers/HighlightSetMetadata';
import LibraryLayerTreeItem from 'types/Layers/LibraryLayerTreeItem';
import { RootState } from './index';
import { clearPresentation } from './presentationSlice';

interface HighlightSetSliceState {
    highlightSets: HighlightSetProps[];
    floorHighlightSets: FloorHighlightSetProps[];
    isHighlightSetStyleOpen?: boolean;
    highlightSetIconsLayer?: FeatureLayer;
    highlightSetProperties: HighlightSetStyleProperties;
    selectedFloors: HighlightFloorsProps[];
    isBuildingHighlightSet?: boolean;
    selectedHighlightItemKey?: string;
    highlightSetInEditMode: LibraryLayerTreeItem | null;
}

const initialState: HighlightSetSliceState = {
    highlightSets: [] as HighlightSetProps[],
    floorHighlightSets: [] as FloorHighlightSetProps[],
    selectedFloors: [] as HighlightFloorsProps[],
    isHighlightSetStyleOpen: false,
    isBuildingHighlightSet: true,
    highlightSetProperties: defaultHighlightSetStyles(),
    highlightSetInEditMode: null,
};

export const highlightSetSlice = createSlice({
    name: 'highlightSet',
    initialState,
    reducers: {
        createFloorHighlightSet(state, action) {
            const highlightSet = action.payload as FloorHighlightSetProps;
            const match = find(state.floorHighlightSets, { key: highlightSet.key });
            if (!match) state.floorHighlightSets.push(highlightSet);
        },
        addPropertyToHighlightSet(state, action) {
            const highlightSet = action.payload as HighlightSetProps;
            const match = find(state.highlightSets, { key: highlightSet.key });
            if (match && highlightSet.properties) {
                match.properties = match.properties?.concat(highlightSet.properties);
            }
        },
        setHighlightSetIconsLayer(state, action) {
            state.highlightSetIconsLayer = action.payload;
        },
        setHighlightSetProperties(state, action) {
            const properties = action.payload;
            state.highlightSetProperties = properties as HighlightSetStyleProperties;
        },
        setSelectedFloors(state, action) {
            state.selectedFloors = action.payload;
        },
        setIsBuildingHighlightSet(state, action) {
            state.isBuildingHighlightSet = HighlightSetType.BUILDING === action.payload;
        },
        setSelectedHighlightItemKey(state, action: PayloadAction<string | undefined>) {
            state.selectedHighlightItemKey = action.payload;
        },
        resetHighlightSetProperties(state) {
            state.highlightSetProperties = defaultHighlightSetStyles();
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const {
    addPropertyToHighlightSet,
    createFloorHighlightSet,
    setHighlightSetIconsLayer,
    setHighlightSetProperties,
    setSelectedFloors,
    setIsBuildingHighlightSet,
    setSelectedHighlightItemKey,
    resetHighlightSetProperties,
} = highlightSetSlice.actions;

export const selectHighlightSetByProp = (key: string | undefined) => (state: RootState) => {
    if (key)
        return state.highlightSet.highlightSets.find((highlightSet: HighlightSetProps) =>
            highlightSet.properties.find((prop: HighlightSetProperty) => key == prop.propertyId)
        );
};

export const selectAllFloorHighlightSets = (state: RootState) => {
    return state.highlightSet.floorHighlightSets;
};

export const selectHighlightSetStyleStatus = (state: RootState) => {
    return state.highlightSet.isHighlightSetStyleOpen;
};

export const selectHighlightSetIconsLayer = (state: RootState) => {
    return state.highlightSet.highlightSetIconsLayer;
};

export const selectHighlightSetProperties = (state: RootState) => {
    return state.highlightSet.highlightSetProperties;
};

export const selectIsBuildingHighlightSet = (state: RootState) => {
    return state.highlightSet.isBuildingHighlightSet;
};

export const selectHighlightItemKey = (state: RootState) => {
    return state.highlightSet.selectedHighlightItemKey;
};

export const selectFloorsSelected = (state: RootState) => {
    return state.highlightSet.selectedFloors;
};

export default highlightSetSlice.reducer;
